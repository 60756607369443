<template>
	<div class="serveDetails">
		<Classification
			:types="1"
			:navData="$store.state.navData"
			:sidebarData="$store.state.sidebarData"
		></Classification>
		<div class="serveDetailsBox flex">
			<div class="fl">
				<div class="common_problem">帮助中心</div>
				<div class="list_Box" v-for="(item, index) in serveList" :key="index">
					<div class="title">{{ item.label }}</div>
					<div
						class="list pointer"
						:class="serviceTypeId == items.id ? 'active' : ''"
						v-for="(items, indexs) in item.children"
						:key="indexs"
						@click="serveBtn(items.id, items.label)"
					>
						{{ items.label }}
					</div>
				</div>
			</div>
			<div class="fr">
				<div class="navBar">
					<div class="name">
						{{ titleName }}
						<div class="line"></div>
					</div>
				</div>
				<div class="contentBoxs">
					<div class="title" v-if="formData != null">{{ formData.title }}</div>
					<div
						class="content"
						v-if="formData != null"
						v-html="formData.content"
					></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Classification from '@/components/classification.vue'

export default {
	components: {
		Classification,
	},
	data() {
		return {
			serveList: [],
			serviceTypeId: null,
			titleName: '',
			formData: {},
			labelName: '',
		}
	},
	mounted() {
		// this.serviceTypeId = this.$route.query.id
		// if(this.$route.query.label){
		//     this.serveBtn(this.serviceTypeId,this.$route.query.label)
		// }
		this.init()
	},
	beforeDestroy() {
		//离开页面清除数据
		sessionStorage.removeItem('serveData')
	},
	methods: {
		init() {
			this.api.findIServerType().then((res) => {
				let serveData = {}
				serveData = res.data[0].children[0]
				this.serveList = res.data
				if (JSON.parse(sessionStorage.getItem('serveData'))) {
					serveData = JSON.parse(sessionStorage.getItem('serveData'))
					if (serveData.id) {
						this.serveBtn(serveData.id, serveData.label)
					}
				} else {
					this.serveBtn(serveData.id, serveData.label)
				}
			})
		},
		serveBtn(id, label) {
			window.scrollTo(0, 0)
			let serveData = {
				id: id,
				label: label,
			}
			sessionStorage.setItem('serveData', JSON.stringify(serveData))
			this.serviceTypeId = id
			this.titleName = label

			this.api.findServer({ serviceTypeId: id }).then((res) => {
				this.formData = res.data
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.serveDetails {
	padding-bottom: 40px;
}
.serveDetailsBox {
	width: 1200px;
	margin: 0 auto;
	margin-top: 30px;
	box-sizing: border-box;
	.fl {
		width: 232px;
		height: 800px;
		background: white;
		box-sizing: border-box;
		.common_problem {
			width: 100%;
			height: 63px;
			font-size: 24px;
			color: #333333;
			padding-left: 27px;
			line-height: 63px;
		}
		.list_Box {
			.title {
				width: 232px;
				height: 40px;
				background: #eaeaea;
				font-size: 16px;
				color: #333333;
				line-height: 40px;
				padding-left: 67px;
				box-sizing: border-box;
				border-left: 2px solid #e62129;
			}
			.list {
				width: 232px;
				height: 40px;
				font-size: 14px;
				color: #666;
				line-height: 40px;
				padding-left: 67px;
				box-sizing: border-box;
			}
			.active {
				color: #e62129 !important;
			}
		}
	}
	.fr {
		width: 958px;
		background: white;
		box-sizing: border-box;
		margin-left: 10px;
		.navBar {
			width: 100%;
			height: 62px;
			border-bottom: 1px solid #eaeaea;
			line-height: 62px;
			padding-left: 32px;
			box-sizing: border-box;
			.name {
				display: inline-block;
				position: relative;
				.line {
					width: 100%;
					height: 2px;
					position: absolute;
					left: 0;
					bottom: 0;
					background: #e62129;
				}
			}
		}
		.contentBoxs {
			width: 958px;
			padding: 27px 32px;
			box-sizing: border-box;
			.title {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
			.content {
				margin-top: 28px;
				font-size: 14px;
				color: #666666;
				>>> img {
					width: 100%;
				}
			}
		}
	}
}
::v-deep iframe {
	width: 100%;
	height: 500px;
}
</style>
<style lang="scss">
.contentBoxs {
	img {
		width: 100%;
	}
}
p {
	font-family: Microsoft YaHei;
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
</style>